<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          prepend-icon="event"
          readonly
          clearable
          v-bind="attrs"
          v-on="on"
          :rules="rules"
          :error-messages="errorMsgs"
          @click:clear="isoDate = null"
        ></v-text-field>
      </template>
      <v-date-picker v-model="isoDate" no-title scrollable locale="it">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(isoDate)">OK</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CalendarFilter",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {},
    "error-messages": {},
  },
  data() {
    return {
      menu: false,
      dateFormatted: "",
      isoDate: null,
    };
  },
  computed: {
    errorMsgs() {
      return this["error-messages"];
    },
  },
  mounted() {
    this.setIsoDate();
  },
  watch: {
    value() {
      this.setIsoDate();
    },
    isoDate() {
      this.dateFormatted = this.formatDate(this.isoDate);
      const newValue = this.isoDate ? new Date(this.isoDate) : null;
      this.$emit("input", newValue);
    },
  },
  methods: {
    setIsoDate() {
      if (!this.value) {
        this.isoDate = null;
        return;
      }
      if (this.value instanceof Date) {
        this.isoDate = moment(this.value).format("YYYY-MM-DD");
      } else {
        this.isoDate = this.value;
      }
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format("DD/MM/YYYY");
    },
    parseDate(date) {
      if (!date) return null;
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>
