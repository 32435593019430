<template>
  <div>
    <TosListVuetify
      serviceName="yard"
      entityName="inventoryVisits"
      :pageTitle="title"
      :headers="headers"
      :excludeButtons="{ add: true }"
      :defaultFilters="defaultFilters"
      :defaultSort="defaultSort"
    >
      <template #customFilters="slotProps">
        <v-container fluid class="ma-0 pa-0">
          <v-row align="center" class="ma-0 pa-0">
            <v-col cols="2">
              <v-select :label="tLabel('Visit status')" v-model="slotProps.data.visitStatusIn" :items="visitStatus" multiple />
            </v-col>
            <v-col cols="2">
              <v-select :label="tLabel('Visit role')" v-model="slotProps.data.visitRoleIn" :items="roles" multiple />
            </v-col>
            <v-col cols="2">
              <CalendarFilter label="Data In Da" v-model="slotProps.data['b.data_inAsDateGreatherThan']" />
            </v-col>
            <v-col cols="2">
              <CalendarFilter label="Data In A" v-model="slotProps.data['b.data_inAsDateLessThan']" />
            </v-col>

            <v-col cols="2">
              <v-text-field :label="tLabel('Unit number')" v-model="slotProps.data['B.object_codeLike']" />
            </v-col>
            <v-col cols="2">
              <v-select :label="tLabel('SysType')" v-model="slotProps.data.sysTypeIn" :items="sysTypes" multiple />
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Tipo Unit')" v-model="slotProps.data['UV.unit_type_codeLike']" />
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Voyage In/Out')" v-model="slotProps.data.voyagesLike" />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #extraButtons="slotProps"> </template>

      <template v-slot:item.actions="slotProps">
      </template>

      <template v-slot:item.actions2="slotProps">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">....</v-btn>
          </template>
          <v-list>
              <v-list-item @click="goToVisitDetails(slotProps.data)">
                <v-list-item-title class="text-uppercase">{{ tLabel("View details") }}</v-list-item-title>
                <v-list-item-action>
                 
                </v-list-item-action>
              </v-list-item>
            </v-list>
        </v-menu>
      </template>

      <template v-slot:item.sysType="slotProps">
        <v-icon large :title="sysTypesMap[slotProps.value]">{{ iconMap[slotProps.value] }}</v-icon>
      </template>
      <template v-slot:item.sysTypeText="slotProps">
        {{ slotProps.data.sysType }}
      </template>

      <template v-slot:item.objectCode="slotProps">
        <div>
          <span></span>
          <span class="text-h6">{{ slotProps.data.objectCode }} </span>
        </div>
      </template>

      <template v-slot:item.status="slotProps">
        <EnumVisualization :value="slotProps.value" :enums="visitStatus" :enumsMap="visitStatusMap" />
      </template>

      <template v-slot:item.status2="slotProps">
        <span class="bordered" :title="visitStatusMap[slotProps.data.status]">{{ slotProps.data.status }}</span>
      </template>

      <template v-slot:item.positionStatus="slotProps">
        <EnumVisualization :value="slotProps.value" :enums="positionStatus" :enumsMap="positionStatusMap" />
      </template>

      <template v-slot:item.positionStatus2="slotProps">
        <span class="bordered" :title="positionStatusMap[slotProps.data.positionStatus]">{{ slotProps.data.positionStatus }}</span>
      </template>

      <template v-slot:item.customsStatus="slotProps">
        <EnumVisualization :value="slotProps.value" :enums="customsStatus" :enumsMap="customsStatusMap" />
      </template>

      <template v-slot:item.customsStatus2="slotProps">
        <span class="bordered" :title="customsStatusMap[slotProps.data.customsStatus]">{{ slotProps.data.customsStatus }}</span>
      </template>

      <template v-slot:item.fullEmpty="slotProps">
        <EnumVisualization :value="slotProps.value" :enums="fullEmpties" :enumsMap="fullEmptiesMap" />
      </template>

      <template v-slot:item.fullEmpty2="slotProps">
        <span class="bordered" :title="fullEmptiesMap[slotProps.data.fullEmpty]">{{ slotProps.data.fullEmpty }}</span>
      </template>

      <template v-slot:item.dangerous="slotProps">
        <div>
          <v-icon v-if="slotProps.value === true" color="warning" large>mdi-alert</v-icon>
        </div>
      </template>

      <template v-slot:item.hinTransportType="slotProps">
        <div>
          <v-icon large :title="transportTypesMap[slotProps.value]">{{ iconMap[slotProps.value] }}</v-icon>
        </div>
      </template>

      <template v-slot:item.houtTransportType="slotProps">
        <div>
          <v-icon large :title="transportTypesMap[slotProps.value]">{{ iconMap[slotProps.value] }}</v-icon>
        </div>
      </template>

      <template v-slot:item.positionCode="slotProps">
        <div v-if="slotProps.data.positionVisitId">
          <span class="text-subtitle-1"> {{ slotProps.data.positionVisitObjectCode }} </span>
        </div>
        <div v-else>
          <span class="text-subtitle-1"> {{ slotProps.value }} </span>
        </div>
      </template>

      <template v-slot:item.qty="slotProps">
        <div v-if="slotProps.data.sysType === 'GOO' && slotProps.data.countType === 'I'">
          <span class="text-subtitle-1"> {{ slotProps.value }}/{{ slotProps.data.qtyInitial }} </span>
        </div>
        <div v-else>
          <span class="text-subtitle-1"> {{ slotProps.value }}</span>
        </div>
      </template>

      <!-- HANDLING IN DATA -->
      <template v-slot:item.hinOrderCode="slotProps">
        <!-- {{slotProps.data.handlingsIn}} -->
        <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
          {{ h.orderCode }}
        </div>
      </template>

      <template v-slot:item.hinVoyageCode="slotProps">
        <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
          {{ h.voyageCode }}
        </div>
      </template>

      <template v-slot:item.hinTransportType="slotProps">
        <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
          <v-icon large :title="transportTypesMap[h.transportType]">{{ iconMap[h.transportType] }}</v-icon>
        </div>
      </template>

      <template v-slot:item.hinTransportTypeText="slotProps">
        <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
          <span :title="transportTypesMap[h.transportType]">{{ h.transportType }}</span>
        </div>
      </template>

      <template v-slot:item.dataIn="slotProps">
        <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
          {{ h.execDate | formatDateTimeFromDate }}
        </div>
      </template>

      <template v-slot:item.hinPlannedDate="slotProps">
        <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
          {{ h.plannedDate | formatDateTimeFromDate }}
        </div>
      </template>

      <!-- HANDLING OUT DATA -->
      <template v-slot:item.houtVoyageCode="slotProps">
        <!-- {{slotProps.data.handlingsOut}} -->
        <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
          {{ h.voyageCode }}
        </div>
      </template>

      <template v-slot:item.houtTransportType="slotProps">
        <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
          <v-icon large :title="transportTypesMap[h.transportType]">{{ iconMap[h.transportType] }}</v-icon>
        </div>
      </template>

      <template v-slot:item.houtTransportTypeText="slotProps">
        <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
          <span :title="transportTypesMap[h.transportType]">{{ h.transportType }}</span>
        </div>
      </template>

      <template v-slot:item.dataOut="slotProps">
        <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
          {{ h.execDate | formatDateTimeFromDate }}
        </div>
      </template>

      <template v-slot:item.dataOut="slotProps">
        <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
          {{ h.execDate | formatDateTimeFromDate }}
        </div>
      </template>

      <template v-slot:item.houtPlannedDate="slotProps">
        <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
          {{ h.plannedDate | formatDateTimeFromDate }}
        </div>
      </template>
    </TosListVuetify>
  </div>
</template>


<script>
import FormatsMixins from "../../../mixins/FormatsMixins";
import YardEnumMixin from "../../../mixins/yard/EnumMixin";
import CalendarFilter from "@/components/anagrafiche/CalendarFilter";
import TosListVuetify from "@/components/anagrafiche/TosListVuetify";

export default {
  name: "AllVisits2",
  props: {
    title: { default: "TUTTE LE VISITE" },
    defaultFilters: { type: Object, default: () => {} },
  },
  data() {
    return {
      item: null,
      includeCarrier: false,
      defaultSort: { field: "data_in", desc: true },
      headers: [
        { text: "", value: "actions2" },
        { text: `${this.tLabel("Type")}`, value: "sysTypeText" },
        { text: `${this.tLabel("Unit")}`, value: "objectCode" },
        { text: `${this.tLabel("Operator")}`, value: "owner" },
        { text: `${this.tLabel("Unit Type")}`, value: "unitTypeCode" },
        { text: `${this.tLabel("ISO Size Type")}`, value: "isoSizeType" },
        { text: `${this.tLabel("Items")}`, value: "qty" },
        { text: `${this.tLabel("WGT")}`, value: "grossweight" },
        { text: `${this.tLabel("Status")}`, value: "status2" },
        { text: `${this.tLabel("Position Status")}`, value: "positionStatus2" },
        { text: `${this.tLabel("Customs Status")}`, value: "customsStatus2" },
        { text: `${this.tLabel("Full/Empty")}`, value: "fullEmpty2" },
        { text: `${this.tLabel("UNDG")}`, value: "dangerous" },
        { text: `${this.tLabel("Position")}`, value: "positionCode" },
        { text: `${this.tLabel("Order")}`, value: "hinOrderCode" },
        { text: `${this.tLabel("In")}`, value: "hinTransportTypeText" },
        { text: `${this.tLabel("Voyage In")}`, value: "hinVoyageCode" },
        { text: `${this.tLabel("Date In")}`, value: "dataIn", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Out")}`, value: "houtTransportTypeText" },
        { text: `${this.tLabel("Voyage Out")}`, value: "houtVoyageCode" },
        { text: `${this.tLabel("Planned Date Out")}`, value: "houtPlannedDate", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Date Out")}`, value: "dataOut", formatValue: "formatDateTimeFromDate" },
      ],
    };
  },
  methods: {
    initFilters(filters) {
      //filters.visitRoleIn=[];
      this.includeCarrier = false;
      this.setCarrierFilters(filters);
    },
    setCarrierFilters(filters) {
      //filters.unitsOnly= this.includeCarrier ? null : true;
      //filters.unitsAndCarrierOnly= this.includeCarrier ? true: null;
    },
    toggle(filters, field) {
      filters[field] = filters[field] ? null : true;
      this.$emit("refreshTable");
    },
    toggleColor(filters, field) {
      return filters[field] ? "primary" : null;
    },
    toggleCarrier(filters) {
      this.includeCarrier = !this.includeCarrier;
      this.setCarrierFilters(filters);
      this.$emit("refreshTable");
    },
  },
  components: { TosListVuetify, CalendarFilter },
  mixins: [FormatsMixins, YardEnumMixin],
};
</script>
